import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import { Button, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel } from "@mui/material";
import { makeStyles } from '@mui/styles';
import config from '../../config.json';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { ClipLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles(() => ({
    App: {
        textAlign: 'center',
    },
    geeks: {
        color: 'green',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '35rem',
        margin: '2% auto',
        boxShadow: '0px 5px 10px gray',
        borderRadius: '15px',
        padding: '3%',
    },
    item: {
        width: '200px',
        margin: '0 auto',
        padding: '2px',
        borderRadius: '10px',
        textAlign: 'left',
    },
    label: {
        width: '150px',
        borderRadius: '5px',
        backgroundColor: '#F59300',
        color: 'white',
        fontSize: 'larger',
        margin: 'auto',
        padding: '3px',
        cursor: 'pointer'
    },
    csvInput: {
        display: 'none',
    },
    dropContainer: {
        border: '2px dashed #F59300',
        borderRadius: '15px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableCell: {
        color: '#F59300',
        '&:hover': {
            backgroundColor: '#F59300',
            color: 'white',
        },
    },
    customToastProgress: {
        backgroundColor: '#F59300',
    },
}));

const headCells = [
    { id: 'refNumber', numeric: false, label: 'Number' },
    { id: 'errorMsg', numeric: false, label: 'Error Message' },
]

const allowedExtensions = ["csv"];

export default function RentalUpload() {
    const classes = useStyles();
    const token = config.TOKEN;
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    var [userType, setUserType] = React.useState("");
    var [userProfile, setUserProfile] = React.useState();
    var [userEmail, setUserEmail] = React.useState("");
    var [rows, setRows] = React.useState([]);
    var [filteredRows, setFilteredRows] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [error, setError] = React.useState("");
    const [file, setFile] = React.useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorData, setErrorData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const errorMessage = () => toast("An error occured, try again later", { progressClassName: classes.customToastProgress });

    useEffect(() => {
        const fetchData = async () => {
            let userProfile = localStorage.getItem("userProfile");
            userProfile = JSON.parse(userProfile);
            setUserProfile(userProfile);
            userType = userProfile.userType;
            setUserType(userType);
            userEmail = userProfile.userEmail;
            setUserEmail(userEmail);
        };

        fetchData();
    }, []);

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }


    function EnhancedTableHead(props) {
        const { classes, order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'center' : 'left'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={{ color: '#F59300', '&:hover': { color: '#F59300' } }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >

                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const onDrop = (acceptedFiles) => {
        const input = acceptedFiles[0];
        handleFileChange(input);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.csv',
        maxFiles: 1,
    });

    const handleFileChange = (input) => {
        const fileExtension = input.name.split('.').pop();

        if (!allowedExtensions.includes(fileExtension)) {
            setError('Please input a csv file');
            return;
        }

        setFile(input);
        setSelectedFiles([input]);
    };


    const handleParse = () => {

        if (!file) return alert("Enter a valid file");
        setLoading(true);

        const reader = new FileReader();

        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, {
                header: true,
            });
            const parsedData = csv?.data;
            const rows = Object.keys(parsedData[0]);
            const columns = Object.values(parsedData[0]);
            const res = rows.reduce((acc, e, i) => {

                return [...acc, [[e], columns[i]]];
            }, []);
            setData(res);
            const postData = {
                userName: userEmail,
                fileName: "Rent-import-dummy.csv",
                fileData: JSON.stringify(parsedData)
            };
            const MOBILESURETY_URL = config.API_URL + "/api/rent/upload";
            axios.post(MOBILESURETY_URL, postData, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    setLoading(false);
                    if (res.data.successCode === 0) {
                        const { success, failed, records } = res.data.data;
                        setRows(records);
                        setFilteredRows(records);
                        toast.success(`Rent File upload completed with ${success} Success and ${failed} Failed records.`, { progressClassName: classes.customToastProgress });
                    } else {
                        setErrorData(res.data.data.records || []);
                        errorMessage()
                    }
                })
        };
        reader.readAsText(file);
    };

    return (
        <div className={classes.App}>
            <ToastContainer theme='dark' />
            <Typography style={{ fontWeight: 'bold', fontSize: '2rem' }}>Rent Upload</Typography>
            <div className={classes.container}>
                <div
                    className={classes.dropContainer}
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    {selectedFiles.length > 0 ? (
                        <div className="selected-file">{selectedFiles[0].name}</div>
                    ) : (
                        'Drag and Drop CSV File or Click to Upload'
                    )}
                </div>
                <div>
                    <div style={{ marginBottom: '1rem' }}></div>
                    <div>
                        <Button type="submit" variant="contained" color="primary" className="no-shadow addBtn" onClick={handleParse}>
                            Submit
                        </Button>
                    </div>
                    <div style={{ marginTop: "3rem" }}>
                        {loading && (
                            <div className="loading-spinner" style={{ marginTop: "1rem" }}>
                                <ClipLoader size={35} color={'#F59300'} loading={loading} />
                            </div>
                        )}
                        {rows.length > 0 && (
                            <TableContainer>
                                <Table className={classes.table}
                                    aria-labelledby="tableTitle"
                                    aria-label="enhanced table">
                                    <EnhancedTableHead
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>
                                        {stableSort(filteredRows, getComparator(order, orderBy))
                                            .map((filteredRows, index) => {
                                                return (
                                                    <TableRow>
                                                        <TableCell>{filteredRows.refNumber}</TableCell>
                                                        <TableCell>{filteredRows.errorMsg}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </div>
                </div>
            </div>
        </div >
    )
}
