import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import axios from 'axios';
import config from '../../config.json';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import GetAppIcon from '@mui/icons-material/GetApp';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import { FilterInput } from '../../../shared/components/FilterInput';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { debounce } from 'lodash';

const TableWrapper = styled.div`

.materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 4px;
            padding-right: 4px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListGetAppIconPosition {
                        display: block;
                    }
                    .actionListVisibilityIconPosition {
                        display: block;
                    }
                    .actionListEmailIconPosition {
                        display: block;
                    }
                    .actionApproveIconPosition{
                        display: block;
                    }
                    .actionRejectIconPosition{
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 88px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEmailIconPosition {
            position: absolute;
            top: -16px;
            right: -24px;
            display: none;
            transition: all 0.3s linear;
            z-index: 1;
        }
         .actionListVisibilityIconPosition {
            position: absolute;
            top: -16px;
            right: 13px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListGetAppIconPosition {
            position: absolute;
            top: -16px;
            right: 51px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionApproveIconPosition{
            position: absolute;
            top: -16px;
            right: 8px;
            display: none;
            transition: all 0.3s linear;
            z-index: 1;
        }
        .actionRejectIconPosition{
            position: absolute;
            top: -16px;
            right: -30px;
            display: none;
            transition: all 0.3s linear;
            z-index: 1;
        }
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionCSVBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
            color: #ffffff;
            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
        .approveActionIcon {
            font-size: 17px;
            font-weight : 1500px ;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 5fr 5fr 0fr 0fr 0fr 0fr 0fr;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    
    .textField {
        width : 300px
    }

    .filterAutocomplete{
    
    .closeIcon { 
        position: absolute;
        left: 450px;
        right: 0px;
        top: 20px;
        bottom: 0px;
    }
    .MuiFilledInput-root {
        background-color: #ffffff;
        border-radius: 0;
        height : 35px;
        padding-top : 0px;
    }
    .MuiFilledInput-input {
        padding: none;
        font-size: 12px;
        height : 10px;
    }
    .MuiFilledInput-underline:before {
        border: 0;
    }
    .MuiInputLabel-filled {
        transform: translate(12px,7px) scale(1);
        font-size: 12px;
    }
    .MuiFilledInput-underline:after {
        border: 0;
    }
    .MuiSelect-select:focus {
        background-color: #ffffff;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(0px,-12px) scale(0.90) !important;
        color: #F59300;
    }}
    .filterDropdown {
        .dialogStyle {
            height : 200px ;
            width : 1000px ;
        }
        .closeIcon { 
            position: absolute;
            left: 450px;
            right: 0px;
            top: 20px;
            bottom: 0px;
        }
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
           
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
        
    }
   
`;

const headCells = [
  { id: 'formDataId', numeric: 'false', label: 'Data Recorded ID' },
  { id: 'currentStatus', numeric: false, label: 'Status' },
  { id: 'receiptDate', numeric: 'false', label: 'Date Of Receipt' },
  { id: 'employeeName', numeric: 'false', label: 'Employee Name' },
  { id: 'vendorName', numeric: 'false', label: 'Vendor Name' },
  { id: 'class', numeric: 'false', label: 'Class' },
  { id: 'expenseDesc', numeric: 'false', label: 'Expense Description' },
  { id: 'expenseAmount', numeric: 'false', label: 'Expense Amount' },
  { id: 'expenseAccount', numeric: 'false', label: 'Expense Account' },
]

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  customToastProgress: {
    backgroundColor: '#F59300',
  },
}));

export default function ExpenseSearch() {
  const classes = useStyles();
  let tokens = localStorage.getItem("token");
  tokens = JSON.parse(tokens)
  const token = config.TOKEN;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [page, setPage] = React.useState(0);
  var [rows, setRows] = React.useState([]);
  var [filteredRows, setFilteredRows] = React.useState([]);
  var [userType, setUserType] = React.useState("");
  var [search, setSearch] = React.useState("");
  var [portalPage, setPortalPage] = React.useState("");
  const currentDate = Moment();
  var [startDate, setStartDate] = React.useState(currentDate.clone().startOf('month').format("yyyy-MM-DD"));
  var [endDate, setEndDate] = React.useState(currentDate.clone().endOf('month').format("yyyy-MM-DD"));
  var [approvePopup, setApprovePopup] = React.useState(false);
  var [openPdf, setOpenPdf] = React.useState(false);
  var [url, setUrl] = React.useState();
  var [open, setOpen] = React.useState(false);
  const [pfreferenceId, setPfreferenceId] = React.useState();
  const [selectAll, setSelectAll] = React.useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [disable, setDisable] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const navigate = useNavigate();
  var [userProfile, setUserProfile] = React.useState();
  var [pfIdArray, setPfIdArray] = React.useState([]);
  var [userEmail, setUserEmail] = React.useState("");
  var [userType, setUserType] = React.useState("");
  var [selectedStatus, setSelectedStatus] = React.useState([]);
  var [pfId, setPfId] = React.useState();
  const [notes, setNotes] = React.useState();
  const notifyApproveWithoutSelected = () => toast("Select atleast one row!", { progressClassName: classes.customToastProgress });
  var [qbClassList, setQbClassList] = React.useState([]);
  var [selectedClasses, setSelectedClasses] = useState([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" style={{ color: '#F59300' }} />;
  const checkedIcon = <CheckBoxIcon fontSize="small" style={{ color: '#F59300' }} />;
  const errorMessage = () => toast("An error occured, try again later", { progressClassName: classes.customToastProgress });
  const pdfMessage = () => toast("No Pdf File Found!", { progressClassName: classes.customToastProgress });
  const emailMessage = () => toast("Emil sent successfully", { progressClassName: classes.customToastProgress });
  const errorMessages = () => toast("An error occured, contact support!", { progressClassName: classes.customToastProgress });
  const approveMessage = () => toast("Form Approved Successfully!", { progressClassName: classes.customToastProgress });
  const rejectMessage = () => toast("Form Rejected Successfully!", { progressClassName: classes.customToastProgress });

  const statusOptions = [
    'New',
    'PreApproved',
    'Approved',
    'Rejected',
    'Processed',
    'Failed'
  ]

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    filteredRows = stabilizedThis.map((el) => el[0])
    return stabilizedThis.map((el) => el[0]);
  }


  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'center' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {userType != "User" && headCell.label == 'Data Recorded ID' && <Checkbox
                checked={selectAll}
                style={{ color: "#F59300", paddingLeft: "13px" }}
                onChange={(event) => selectAllRows(event)}
              />}
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >

                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  useEffect(() => {

    userProfile = localStorage.getItem("userProfile");
    userProfile = JSON.parse(userProfile);
    setUserProfile(userProfile);
    userEmail = userProfile?.userEmail;
    setUserEmail(userEmail);
    userType = userProfile?.userType;
    setUserType(userType);

    expenseSearch()
    searchExpense()

  }, [portalPage]);

  const expenseSearch = () => {
    var batchDate = sessionStorage.getItem("expense_dateRange")
    if (batchDate) {
      batchDate = JSON.parse(batchDate)
      startDate = batchDate.d_startDate
      setStartDate(startDate)
      endDate = batchDate.d_endDate
      setEndDate(endDate)
      selectedStatus = batchDate.d_selectedStatus
      setSelectedStatus(selectedStatus)
      selectedClasses = batchDate.d_selectedClasses
      setSelectedClasses(selectedClasses)
      searchFormData()
    } else {
      if (initialLoad) {
        const axios = require('axios');
        const postData = {
          "PortalPage": "Expense Search",
          "startDate": startDate + "T00:00:00",
          "endDate": endDate + "T23:59:00",
          "pfDataId": "",
          "status": selectedStatus,
          "qbClass": selectedClasses,
          "userEmail": userEmail,
          "filterText": search
        };

        const MOBILESURETY_EXPENSE_SEARCH_URL = config.API_URL + "/api/expense/search";
        const token = config.TOKEN;

        axios.post(MOBILESURETY_EXPENSE_SEARCH_URL, postData, {
          headers: {
            'Authorization': `Basic ${token}`
          }
        })
          .then((res) => {
            if (res.data.successCode === 0) {
              const rows = res.data.data;
              setRows(rows);
              setFilteredRows(rows);
            } else {
              errorMessage()
            }
          });
        setInitialLoad(false);
      }
    }
  }

  const searchExpense = () => {
    const MOBILESURETY_USER_URL = config.API_URL + "/api/qbclass/all/" + userEmail
    axios.get(MOBILESURETY_USER_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          let employees = res.data.data
          qbClassList = employees.filter(e => e.isActive == true).map(e => ({ "className": e.className, "id": e.id }))
          setQbClassList(qbClassList)
        } else {
          errorMessage()
        }
      })
  }

  const searchFormData = () => {

    var dateRange = {
      "d_startDate": startDate,
      "d_endDate": endDate,
      "d_selectedStatus": selectedStatus,
      "d_selectedClasses": selectedClasses
    }
    dateRange = JSON.stringify(dateRange)
    sessionStorage.setItem("expense_dateRange", dateRange);

    var postData = {
      "PortalPage": "Expense Search",
      "startDate": startDate + "T00:00:00",
      "endDate": endDate + "T23:59:00",
      "status": selectedStatus,
      "qbClass": selectedClasses,
      "userEmail": userEmail,
      "filterText": search
    }
    const MOBILESURETY_URL = config.API_URL + "/api/expense/search"
    const token = config.TOKEN

    axios.post(MOBILESURETY_URL, postData, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          rows = res.data.data
          setRows(rows);
          filteredRows = rows;
          setFilteredRows(rows);
        } else {
          errorMessage()
        }
      })
  }

  const handleClassChange = (event, value) => {
    setSelectedClasses(value);
  };

  const downloadcsv = () => {

    var postData = {
      "PortalPage": portalPage,
      "startDate": startDate + "T00:00:00",
      "endDate": endDate + "T23:59:00",
      "status": selectedStatus,
      "qbClass": selectedClasses,
      "userEmail": userEmail
    }
    const MOBILESURETY_URL = config.API_URL + "/api/expense/download"
    const token = config.TOKEN

    axios.post(MOBILESURETY_URL, postData, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          const data = res.data.data;
          const element = document.createElement("a");
          const file = new Blob([data], { type: 'text/plain' });
          element.href = URL.createObjectURL(file);
          element.download = 'expenses.csv';
          document.body.appendChild(element);
          element.click();
        } else {
          errorMessage()
        }
      })
  }

  const handleSearch = () => {
    searchFormData()
  }

  const handleKeySearch = (event) => {
    var search = event.target.value;
    setSearch(search);
    filteredRows = rows.filter(a =>
      (a.formDataId.toLowerCase().includes(search.toLowerCase())));
    setFilteredRows(filteredRows)
  }

  const recordedId = (pfDataId) => {
    navigate("/expense/edit/" + pfDataId);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const downloadPdf = (pfreferenceId) => {
    const MOBILESURETY_DOWNLOAD_URL = config.API_URL + "/api/formsearch/file/" + pfreferenceId
    const token = config.TOKEN
    axios.get(MOBILESURETY_DOWNLOAD_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        var respData = res.data;

        if (respData.successCode === 0) {
          if (respData.data.fileData != null && respData.data.fileName != null) {
            const url = 'data: application/pdf;base64,' + respData.data.fileData
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', respData.data.fileName);
            document.body.appendChild(link);
            link.click();
          }
          else {
            pdfMessage()
          }
        } else {
          errorMessage()
        }

      }).catch(err => {
        console.log(err)
      })
  }

  const handlepdfOpen = (pfDataId) => {
    const MOBILESURETY_DOWNLOAD_URL = config.API_URL + "/api/formsearch/file/" + pfDataId
    const token = config.TOKEN
    axios.get(MOBILESURETY_DOWNLOAD_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        var respData = res.data;
        if (respData.successCode === 0) {

          if (respData.data.fileData != null && respData.data.fileName != null) {
            url = 'data: application/pdf;base64,' + respData.data.fileData;
            setUrl(url)
            setOpenPdf(true);
          } else {
            pdfMessage()
          }
        } else {
          errorMessage()
        }

      }).catch(err => {
        console.log(err)
      })
  }

  const handleClickOpen = (pfDataId) => {
    setPfreferenceId(pfDataId)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClosePdf = () => {
    setOpenPdf(false)
  }

  const sendEmail = () => {
    setDisable(true)
    const MOBILESURETY_URL = config.API_URL + "/api/formsearch/sendemail"
    const token = config.TOKEN
    var postData = {
      "pfReferenceId": pfreferenceId,
      "emailList": email,
      "FormName": ""
    }
    axios.post(MOBILESURETY_URL, postData, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        {
          if (res.data.successCode === 0) {
            emailMessage();
          } else {
            errorMessages()
          }
          setDisable(false)
        }
      })
  }

  const approveForm = (filteredRows, action) => {
    pfIdArray.push(filteredRows.formDataId)
    setPfIdArray(pfIdArray);
    var postData = {
      "dataIdList": pfIdArray,
      "approvalAction": action,
      "actionUser": userEmail,
      "entityType": 'Expense'
    }
    approveReject(postData, action)
  }

  const rejectForm = (action) => {
    pfIdArray.push(pfId)
    setPfIdArray(pfIdArray);

    var postData = {
      "dataIdList": pfIdArray,
      "approvalAction": action,
      "actionUser": userEmail,
      "entityType": 'Expense',
      "notes": notes
    }
    approveReject(postData, action)
  }

  const approveSelected = () => {
    pfIdArray = filteredRows.filter(x => x.select === true).map(f => f.formDataId)
    setPfIdArray(pfIdArray);
    if (pfIdArray.length > 0) {
      var postData = {
        "dataIdList": pfIdArray,
        "approvalAction": "Approved",
        "actionUser": userEmail,
        "entityType": 'Expense',
        "approvalModel": []
      }
      setSelectAll(false)
      for (let index = 0; index < filteredRows.length; index++) {
        let element = filteredRows[index];
        element['select'] = false
      }
      approveReject(postData, "Approved")
    }
    else {
      notifyApproveWithoutSelected()
    }
  }

  const approveReject = (postData, action) => {
    pfIdArray = [];
    setPfIdArray(pfIdArray);
    const MOBILESURETY_EXPENSE_SEARCH_URL = config.API_URL + "/api/submissions/approval";
    const token = config.TOKEN;

    axios.post(MOBILESURETY_EXPENSE_SEARCH_URL, postData, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          if (action == "Approved") {
            setPfIdArray([])
            approveMessage()
          }
          else {
            rejectMessage()
            setNotes('')
            setTimeout(() => {
              approvePopup = false;
              setApprovePopup(approvePopup);
            }, 5000)
          }
          searchFormData()
        } else {
          if (action == "Approved") {
            errorMessage()
          } else {
            errorMessage()
            setTimeout(() => {
              approvePopup = false;
              setApprovePopup(approvePopup)
            }, 2000)
          }
        }
      })
  }


  const icons = (filteredRows) => {
    return (
      <div>
        <div className="actionListGetAppIconPosition">
          <Tooltip title="Download" aria-label="Download">
            <IconButton className="actionBtn" aria-label="Download" onClick={() => { downloadPdf(filteredRows.formDataId) }}>
              <GetAppIcon className="actionIcon" />
            </IconButton>
          </Tooltip>
        </div>
        <div className="actionListEmailIconPosition">
          <Tooltip title="Email" aria-label="Email">
            <IconButton className="actionBtn" aria-label="Email" onClick={() => handleClickOpen(filteredRows.formDataId)}>
              <EmailIcon className="actionIcon" />
            </IconButton>
          </Tooltip>
        </div>
        <div className="actionListVisibilityIconPosition">
          <Tooltip title="View" aria-label="View">
            <IconButton className="actionBtn" aria-label="View" onClick={() => handlepdfOpen(filteredRows.formDataId)}>
              <VisibilityIcon className="actionIcon" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    )
  }

  const openRejectPopup = (filteredRows) => {
    pfId = filteredRows.formDataId;
    setPfId(pfId);
    approvePopup = true;
    setApprovePopup(approvePopup);
  }

  const closeRejectPopup = () => {
    approvePopup = false;
    setApprovePopup(approvePopup);
    setNotes('')
  }

  const approve = (filteredRows) => {
    const approvalAction = filteredRows.currentStatus;

    return (
      <div>
        {userType !== "User" && (
          <div className="actionApproveIconPosition">
            <Tooltip title="Approve" aria-label="Approve">
              <IconButton className="actionBtn" aria-label="Approve" onClick={() => approveForm(filteredRows, "Approved")}>
                <CheckIcon className="approveActionIcon" />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {userType !== "User" && (
          <div className="actionRejectIconPosition">
            <Tooltip title="Reject" aria-label="Reject">
              <IconButton className="actionBtn" aria-label="Reject" onClick={() => openRejectPopup(filteredRows)}>
                <ClearIcon className="actionIcon" />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {approvalAction}
      </div>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    isSelectedCheckboxes(newPage)
  };

  const isSelectedCheckboxes = (page) => {
    const trimStart = page * rowsPerPage
    const trimEnd = trimStart + rowsPerPage
    const selectedRows = filteredRows.slice(trimStart, trimEnd)
    if (selectedRows.every(x => x.select === true)) {
      selectAll = true
    } else {
      selectAll = false
    }
    setSelectAll(selectAll)
  }

  const selectAllRows = (event) => {

    setSelectAll(event.target.checked)
    const trimStart = page * rowsPerPage
    const trimEnd = trimStart + rowsPerPage
    for (let index = trimStart; index < trimEnd; index++) {
      let element = filteredRows[index];
      element['select'] = event.target.checked
    }
  }

  const onChangeCheckedBox = (checked, index) => {
    let pageIndex = index + (page * rowsPerPage)
    filteredRows[pageIndex]['select'] = checked
    filteredRows = [...filteredRows]
    setFilteredRows(filteredRows)
  }

  const debouncedNotesHandler = debounce((value) => {
    setNotes(value)
  }, 300);

  const debouncedEmailHandler = debounce((value) => {
    setEmail(value)
  }, 300)

  return (
    <div>
      <ToastContainer theme="dark" />
      <TableWrapper>
        <div className={`${classes.root} materialTableStyle`}>
          <div className="filterRow">
            <div>
              <FilterInput placeholder="Search" disabled={disable} icon={<FilterListIcon className="filterIconSize" />} value={search} onChange={handleKeySearch} />
            </div>
            <div>
              <FilterInput style={{ height: "35px" }} placeholder="Start Date(yyyy-mm-dd)" type="date" value={startDate} onChange={(event) => setStartDate(event.target.value)} />
            </div>
            <div>
              <FilterInput style={{ height: "35px" }} placeholder="End Date(yyyy-mm-dd)" type="date" value={endDate} onChange={(event) => setEndDate(event.target.value)} />
            </div>
            <div>
              <Autocomplete
                multiple
                id="status"
                size="small"
                limitTags={1}
                options={statusOptions}
                className="filterAutocomplete"
                getOptionLabel={(option) => option}
                onChange={(event, status) => {
                  selectedStatus = status
                  setSelectedStatus(selectedStatus);
                }}
                value={selectedStatus}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      checked={selected}
                    />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="Status" className="filter" variant="filled" />
                )}
              />
            </div>
            <div>
              <Autocomplete
                multiple
                id="qbclass"
                size="small"
                limitTags={1}
                options={qbClassList}
                className="filterAutocomplete"
                getOptionLabel={(option) => option.className}
                onChange={handleClassChange}
                value={selectedClasses}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.className}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="QB Class" className="filter" variant="filled" />
                )}
              />
            </div>
            <div>
              <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={handleSearch}>
                Search
              </Button>
            </div>
            {userType !== "User" && <span>
              <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={approveSelected} >
                Approve
              </Button>
            </span>}
            <span>
              <div>
                <Tooltip title="Download CSV" aria-label="Download">
                  <IconButton className="actionCSVBtn csvDownloadIcon" aria-label="Download" onClick={() => { downloadcsv() }}>
                    <GetAppIcon className="actionIcon" />
                  </IconButton>
                </Tooltip>
              </div>
            </span>
          </div>
          <TableContainer>
            <Dialog open={openPdf} closeonescape="true" height="600px" fullWidth={true} maxWidth="lg" >
              <TableWrapper>
                <DialogTitle>
                  <div className="text-right">
                    <FontAwesomeIcon icon={faWindowClose} className="closeIcon" onClick={handleClosePdf} />
                  </div>
                </DialogTitle>
                <DialogContent>
                  <div className="text-right">
                    <div style={{
                      border: '1px solid rgba(0,0,0,0.3)',
                      height: '750px',
                      width: '100%'
                    }}>
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js">
                        <Viewer width="100%"
                          fileUrl={url} />
                      </Worker>
                    </div>
                  </div>
                </DialogContent>
              </TableWrapper>
            </Dialog>
            <Dialog open={open} closeonescape="true" fullWidth={true} maxWidth="sm" >
              <DialogTitle>
                <div className="text-right">
                  <FontAwesomeIcon icon={faWindowClose} className="closeIcon" onClick={handleClose} />
                </div>
                Email
              </DialogTitle>
              <DialogContent className="dialogStyle">
                <TextField fullWidth label="Email Id" variant="standard" onChange={(event) => debouncedEmailHandler(event.target.value)} />
                <DialogActions>
                  <Grid container spacing={4} className="mt-3">
                    <Grid item xs={12} sm={12} md={12} className="text-right">
                      <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={handleClose}>
                        Cancel
                      </Button>&nbsp;&nbsp;
                      <Button type="submit" disabled={disable} variant="contained" color="primary" className="noShadow addBtn" onClick={() => sendEmail()}>
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
                <ToastContainer theme="dark" />
              </DialogContent>
            </Dialog>
            <Dialog open={approvePopup} closeonescape="true" fullWidth={true} maxWidth="sm" >
              <DialogTitle>
                <div className="text-right">
                  <FontAwesomeIcon icon={faWindowClose} className="closeIcon" onClick={closeRejectPopup} />
                </div>
                Reject
              </DialogTitle>
              <DialogContent>
                <TextField id="notes" fullWidth multiline label="Notes" variant="standard" onChange={(event) => debouncedNotesHandler(event.target.value)} />
                <DialogActions>
                  <Grid container spacing={4} className="mt-3">
                    <Grid item xs={12} sm={12} md={12} className="text-center">
                      <Button type="submit" disabled={disable} variant="contained" color="primary" className="noShadow addBtn" onClick={() => rejectForm("Rejected")}>
                        Confirm
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
                <ToastContainer theme="dark" />
              </DialogContent>
            </Dialog>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table">
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(filteredRows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((filteredRows, index) => {
                    return (
                      <TableRow hover
                        key={index}>
                        <TableCell align='center'>
                          <div className='d-flex'>{userType != "User" && <Checkbox
                            checked={filteredRows.select === true}
                            style={{ color: "#F59300" }}
                            onChange={(event) => onChangeCheckedBox(event.target.checked, index)}
                          />}
                            <span className='mt-3'>{filteredRows.formDataId}</span>
                          </div>
                          <div className='actionListEditIconPosition'>
                            <Tooltip title='Edit' aria-label='Edit'>
                              <IconButton className='actionBtn' aria-label='edit' onClick={() => recordedId(filteredRows.formDataId)}>
                                <EditIcon className='actionIcon' />
                              </IconButton>
                            </Tooltip>
                          </div>
                          {icons(filteredRows)}
                        </TableCell>
                        <TableCell>
                          {approve(filteredRows)}
                        </TableCell>
                        <TableCell >
                          {Moment(Date.parse(filteredRows.receiptDate)).format('MM/DD/YYYY')}
                        </TableCell>
                        <TableCell >
                          {filteredRows.employeeName}
                        </TableCell>
                        <TableCell >
                          {filteredRows.vendorName}
                        </TableCell>
                        <TableCell >
                          {filteredRows.class}
                        </TableCell>
                        <TableCell >
                          {filteredRows.expenseDesc}
                        </TableCell>
                        <TableCell >
                          {filteredRows.expenseAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                        </TableCell>
                        <TableCell >
                          {filteredRows.expenseAccount}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className="paginationLabel"
            rowsPerPageOptions={[100, 150, 200]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

        </div>
      </TableWrapper>
    </div>
  )
}
