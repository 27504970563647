import React, { useEffect } from "react";
import { Formik } from 'formik';
import { Button, TextField, Grid, MenuItem } from '@mui/material';
import axios from 'axios';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import config from '../../config.json';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    },
    actionBtn: {
        padding: '8px',
        backgroundColor: '#f0f0f0',
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        fontSize: '14px',
        '&:hover': {
            backgroundColor: 'rgba(245,147,0,0.1)',
            color: '#F59300',
        },
        '&.active': {
            backgroundColor: '#F59305',
            color: 'white !important',
        },
    },
    customToastProgress: {
        backgroundColor: '#F59300',
    },
}));


const UserWrapper = styled.div`
      position: relative;
      .css-sghohy-MuiButtonBase-root-MuiButton-root {
          background-color: #F59300 !important;
      }
      .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
          border : 1px solid #F59300 !important;
          color : #F59300 !important;
      }
  
      .wrapperWhite {
          background-color: #ffffff;
          padding: 1.5rem;
          border-radius: 18px;
      }
  
      .MuiTextField-root {
          width: 100%;
      }
  
      .card-box{
          position: relative;
          overflow: hidden;
          box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
          border-radius: 0.5rem;
          color: rgba(0, 0, 0, 0.87);
          transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          background-color: #fff;        
          cursor: pointer;
      }
      .card-indicator {
          position: absolute;
          height: 60%;
          top: 20%;
          width: 0px;
          border-top-right-radius: .75rem;
          border-bottom-right-radius: .75rem;
          left: 0;
          transition: all 0.3s linear;
      }
      .badge-primary {
          color: #fff;
          font-weight: 500;    
      }
      .roleName {
          font-size: 14px;
          font-weight: 500;
      }
      .roleItems { 
          width: 100%;
          height: 100%;
          position: absolute;
          opacity: 0;
          cursor: pointer;
      }
      .roleItems:checked ~ .card-indicator {
          width: 6px;
      }
      .customWidth : {
          width : 100% !important
      }
  `;

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function AddServiceSearch() {
    const classes = useStyles();
    const navigate = useNavigate();
    const { id } = useParams();
    const token = config.TOKEN;
    let tokens = localStorage.getItem("token");
    tokens = JSON.parse(tokens)
    var [userProfile, setUserProfile] = React.useState();
    var [userType, setUserType] = React.useState("");
    var [userEmail, setUserEmail] = React.useState("");
    const [value, setValue] = React.useState(0);
    const [rows, setRows] = React.useState({});
    const errorMessage = () => toast("An error occured, try again later", { progressClassName: classes.customToastProgress });
    const serviceUpdateMessage = () => toast("Expense Updated Successfully!", { progressClassName: classes.customToastProgress })
    const statusOptions = [
        'New',
        'PreApproved',
        'Approved',
        'Rejected',
        'Proceed',
        'Failed'
    ]
    var [isRepeatableSection, setIsRepeatableSection] = React.useState(false);
    var [currentIndex, setCurrentIndex] = React.useState(0);
    var [serviceDataItems, setServiceDataItems] = React.useState([]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        userProfile = localStorage.getItem("userProfile");
        userProfile = JSON.parse(userProfile);
        setUserProfile(userProfile);
        userType = userProfile.userType;
        setUserType(userType);
        userEmail = userProfile.userEmail;
        setUserEmail(userEmail);
        if (id) {
            const MOBILESURETY_URL = config.API_URL + "/api/servicesearch/edit/" + id;
            axios.get(MOBILESURETY_URL, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {

                    if (res.data.successCode === 0) {
                        const rows = res.data.data;
                        rows.serviceHeader.dateOfService = new Date(rows.serviceHeader.dateOfService).toISOString().split('T')[0];
                        setRows(rows);
                        serviceDataItems = rows.serviceDataItems
                        setServiceDataItems(serviceDataItems)
                    } else {
                        errorMessage()
                    }
                })
                .catch(error => {
                    console.error("API Error:", error);
                });
        }
    }, [id])


    const displayNextButton = () => {
        if (isRepeatableSection && serviceDataItems.length > 0)
            return (
                <Button variant="contained" color="primary" className="no-shadow addBtn" onClick={() => handleNextClick()} disabled={currentIndex === serviceDataItems.length - 1}>
                    Next
                </Button>
            )
    }

    const handleNextClick = () => {
        currentIndex = currentIndex + 1
        setCurrentIndex(currentIndex);
    };

    const handleServiceData = (e, fieldName) => {
        serviceDataItems[currentIndex][fieldName] = e.target.value
        serviceDataItems = [...serviceDataItems]
        setServiceDataItems(serviceDataItems)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label='HEADER PAGE' {...a11yProps(0)} style={{ color: '#F59300' }} onClick={() => { isRepeatableSection = false; setIsRepeatableSection(isRepeatableSection) }} />
                    <Tab label='REPEATABLE SERVICE PG' {...a11yProps(1)} style={{ color: '#F59300' }} onClick={() => { isRepeatableSection = true; setIsRepeatableSection(isRepeatableSection) }} />
                    <Tab label='SIGNATURE PAGE' {...a11yProps(2)} style={{ color: '#F59300' }} onClick={() => { isRepeatableSection = false; setIsRepeatableSection(isRepeatableSection) }} />
                    <Tab label='MANIFEST/TICKET' {...a11yProps(3)} style={{ color: '#F59300' }} onClick={() => { isRepeatableSection = false; setIsRepeatableSection(isRepeatableSection) }} />
                    <Tab label='HOURS/MILES' {...a11yProps(4)} style={{ color: '#F59300' }} onClick={() => { isRepeatableSection = false; setIsRepeatableSection(isRepeatableSection) }} />
                </Tabs>
            </Box>
            <Formik enableReinitialize={true}
                initialValues={{
                    pfDataId: id,
                    status: rows?.status,
                    dateOfService: rows?.serviceHeader?.dateOfService || new Date().toISOString().split('T')[0],
                    Location: rows?.serviceHeader?.location,
                    CustomerRigName: rows?.serviceHeader?.customerRigName,
                    CustomerNumber: rows?.serviceHeader?.customerNumber,
                    PriceLevel: rows?.serviceHeader?.priceLevel,
                    LeaseStatus: rows?.serviceHeader?.leaseStatus,
                    LeaseName: rows?.serviceHeader?.leaseName,
                    LeaseCounty: rows?.serviceHeader?.leaseCounty,
                    LeaseState: rows?.serviceHeader?.leaseState,
                    QbClass: rows?.serviceHeader?.qbClass,
                    ServiceType: rows?.serviceDataItem?.serviceType,
                    ServiceDescription: rows?.serviceDataItem?.serviceDescription,
                    Item: rows?.serviceDataItem?.item,
                    Qty: rows?.serviceDataItem?.qty,
                    Rate: rows?.serviceDataItem?.rate,
                    Extension: rows?.serviceDataItem?.extension,
                    BarcodeScan: rows?.serviceDataItem?.barcodeScan,
                    AssetNumber: rows?.serviceDataItem?.assetNumber,
                    T3PUnitNumber: rows?.serviceDataItem?.t3PUnitNumber,
                    InventoryUpdate: rows?.serviceDataItem?.inventoryUpdate,
                    ServiceProvidedBy: rows?.signaturePage?.serviceProvidedBy,
                    ServiceTypes: rows?.signaturePage?.serviceType,
                    SignatureOnly: "",
                    Content: rows?.manifest?.content,
                    ChargedQty: rows?.manifest?.chargedQty,
                    ManifestNumber: rows?.manifest?.manifestNumber,
                    Site: rows?.manifest?.site,
                    Hours: rows?.serviceHour?.hours,
                    Minutes: rows?.serviceHour?.minutes,
                    Miles: rows?.serviceHour?.miles,
                    Time: rows?.serviceHour?.time,
                    qbError: rows?.qbError
                }}
                onSubmit={(values, { setSubmitting }) => {
                    const postData = {
                        pfDataId: id ? id : 0,
                        status: values.status,
                        userName: userEmail,
                        qbError: values.qbError,
                        serviceHeader: {
                            dateOfService: values.dateOfService,
                            location: values.Location,
                            customerRigName: values.CustomerRigName,
                            customerNumber: values.CustomerNumber,
                            priceLevel: values.PriceLevel,
                            leaseStatus: values.LeaseStatus,
                            leaseName: values.LeaseName,
                            leaseCounty: values.LeaseCounty,
                            leaseState: values.LeaseState,
                            qbClass: values.QbClass,
                        },
                        // serviceDataItems: {
                        //     serviceType: values.ServiceType,
                        //     serviceDescription: values.ServiceDescription,
                        //     item: values.Item,
                        //     qty: values.Qty,
                        //     rate: values.Rate,
                        //     extension: values.Extension,
                        //     barcodeScan: values.BarcodeScan,
                        //     assetNumber: values.AssetNumber,
                        //     t3PUnitNumber: values.T3PUnitNumber,
                        //     inventoryUpdate: values.InventoryUpdate,
                        // },
                        serviceDataItems: serviceDataItems,
                        signaturePage: {
                            serviceProvidedBy: values.ServiceProvidedBy,
                            serviceType: values.ServiceTypes,
                            signatureOnly: values.SignatureOnly,
                        },
                        manifest: {
                            content: values.Content,
                            chargedQty: values.ChargedQty,
                            manifestNumber: values.ManifestNumber,
                            site: values.Site,
                        },
                        serviceHour: {
                            hours: values.Hours,
                            minutes: values.Minutes,
                            miles: values.Miles,
                            time: values.Time,
                            projectJobLocation: ""
                        }
                    }
                    const MOBILESURETY_URL = config.API_URL + "/api/service/save";
                    axios.post(MOBILESURETY_URL, postData, {
                        headers: {
                            'Authorization': `Basic ${token}`
                        }
                    })
                        .then((res) => {
                            if (res.data.successCode === 0) {
                                serviceUpdateMessage()
                            } else {
                                errorMessage()
                            }
                            setTimeout(() => { navigate("/servicesearch/list") }, 3000)
                        })
                        .catch((error) => {
                            errorMessage()
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}>
                {({ handleChange, handleBlur, values, setValues, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                        <CustomTabPanel value={value} index={0}>
                            <UserWrapper>
                                <ToastContainer theme="dark" />
                                <Grid container spacing={4} className="d-flex justify-content-center">
                                    <Grid container spacing={2} style={{ margin: '20px' }}>
                                        <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                                            <TextField style={{ width: "100%" }}
                                                className="filterDropdown"
                                                id="status"
                                                select
                                                label="Select Class"
                                                helperText=""
                                                variant="standard"
                                                name="status"
                                                value={values.status || ""}
                                                onChange={(e) => setValues({ ...values, status: e.target.value })}
                                                onBlur={handleBlur}>
                                                {statusOptions.map((status, index) => (
                                                    <MenuItem key={index} value={status}>
                                                        {status}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Date of Service' variant='standard' type="Date" onChange={(e) => {
                                                handleChange(e)
                                                setValues({ ...values, dateOfService: e.target.value })
                                            }} onBlur={handleBlur} value={values.dateOfService} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Location' variant='standard' name="Location" onChange={handleChange} onBlur={handleBlur} value={values.Location || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Choose Customer Name' variant='standard' name="CustomerRigName" onChange={handleChange} onBlur={handleBlur} value={values.CustomerRigName || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Customer Number' variant="standard" name="CustomerNumber" onChange={handleChange} onBlur={handleBlur} value={values.CustomerNumber || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Price Level' variant="standard" name="PriceLevel" onChange={handleChange} onBlur={handleBlur} value={values.PriceLevel || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Lease Status' variant="standard" name="LeaseStatus" onChange={handleChange} onBlur={handleBlur} value={values.LeaseStatus || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Lease Name' variant="standard" name="LeaseName" onChange={handleChange} onBlur={handleBlur} value={values.LeaseName || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Lease County' variant="standard" name="LeaseCounty" onChange={handleChange} onBlur={handleBlur} value={values.LeaseCounty || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Lease State' variant="standard" name="LeaseState" onChange={handleChange} onBlur={handleBlur} value={values.LeaseState || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Lease Country/State/Class' variant="standard" name="QbClass" onChange={handleChange} onBlur={handleBlur} value={values.QbClass || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} className="pt-3">
                                            <TextField label="Qb Status" variant="standard" name="QbStatus" value={values.qbError || ""} disabled />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </UserWrapper>
                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={1}>
                            <UserWrapper>
                                <Grid container spacing={4} className="d-flex justify-content-center">
                                    <Grid container spacing={2} style={{ margin: '20px' }}>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Service Type' variant="standard" name="serviceType" onChange={(e) => handleServiceData(e, 'serviceType')} value={serviceDataItems[currentIndex]?.serviceType} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Service Description' variant="standard" name="ServiceDescription" onChange={(e) => handleServiceData(e, 'serviceDescription')} onBlur={handleBlur} value={serviceDataItems[currentIndex]?.serviceDescription} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Item #' variant="standard" name="Item" onChange={(e) => handleServiceData(e, 'item')} onBlur={handleBlur} value={serviceDataItems[currentIndex]?.item} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Service City' variant="standard" name="Qty" onChange={(e) => handleServiceData(e, 'qty')} onBlur={handleBlur} value={serviceDataItems[currentIndex]?.qty} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Service Price' variant="standard" name="Rate" onChange={(e) => handleServiceData(e, 'rate')} onBlur={handleBlur} value={serviceDataItems[currentIndex]?.rate} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Service Extension' variant="standard" name="Extension" onChange={(e) => handleServiceData(e, 'extension')} onBlur={handleBlur} value={serviceDataItems[currentIndex]?.extension} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Barcode Addl Scan' variant="standard" name="BarcodeScan" onChange={(e) => handleServiceData(e, 'barcodeScan')} onBlur={handleBlur} value={serviceDataItems[currentIndex]?.barcodeScan} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label="**WRP Addl Assets** If not on list manually enter into 'Description'" variant="standard" name="AssetNumber" onChange={(e) => handleServiceData(e, 'assetNumber')} onBlur={handleBlur} value={serviceDataItems[currentIndex]?.assetNumber} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Description' variant="standard" name="T3PUnitNumber" onChange={(e) => handleServiceData(e, 't3PUnitNumber')} onBlur={handleBlur} value={serviceDataItems[currentIndex]?.t3PUnitNumber} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Inventory Update' variant="standard" name="InventoryUpdate" onChange={(e) => handleServiceData(e, 'inventoryUpdate')} onBlur={handleBlur} value={serviceDataItems[currentIndex]?.inventoryUpdate} />
                                        </Grid>
                                    </Grid>
                                </Grid >
                            </UserWrapper>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <UserWrapper>
                                <Grid container spacing={4} className="d-flex justify-content-center">
                                    <Grid container spacing={2} style={{ margin: '20px' }}>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Service Provided by' variant="standard" name="ServiceProvidedBy" onChange={handleChange} onBlur={handleBlur} value={values.ServiceProvidedBy || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Service Type' variant="standard" name="ServiceTypes" onChange={handleChange} onBlur={handleBlur} value={values.ServiceTypes || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Signature Only' variant="standard" name="SignatureOnly" onChange={handleChange} onBlur={handleBlur} value={values.SignatureOnly || ""} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </UserWrapper>
                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={3}>
                            <UserWrapper>
                                <Grid container spacing={4} className="d-flex justify-content-center">
                                    <Grid container spacing={2} style={{ margin: '20px' }}>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Content' variant="standard" name="Content" onChange={handleChange} onBlur={handleBlur} value={values.Content || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Charged Qty' variant="standard" name="ChargedQty" onChange={handleChange} onBlur={handleBlur} value={values.ChargedQty || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Manifest/Ticket #' variant="standard" name="ManifestNumber" onChange={handleChange} onBlur={handleBlur} value={values.ManifestNumber || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Site' variant="standard" name="Site" onChange={handleChange} onBlur={handleBlur} value={values.Site || ""} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </UserWrapper>
                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={4}>
                            <UserWrapper>
                                <Grid container spacing={4} className="d-flex justify-content-center">
                                    <Grid container spacing={2} style={{ margin: '20px' }}>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content-center">
                                            <TextField label='Hours' variant="standard" name="Hours" onChange={handleChange} onBlur={handleBlur} value={values.Hours || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content center">
                                            <TextField label='Minutes' variant="standard" name="Minutes" onChange={handleChange} onBlur={handleBlur} value={values.Minutes || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content center">
                                            <TextField label='Miles' variant="standard" name="Miles" onChange={handleChange} onBlur={handleBlur} value={values.Miles || ""} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="d-flex justify-content center">
                                            <TextField label='Time' variant="standard" name="Time" onChange={handleChange} onBlur={handleBlur} value={values.Time || ""} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </UserWrapper>
                        </CustomTabPanel>

                        <Grid item xs={12} sm={10} md={10} className="text-right mt-2">
                            {displayNextButton()}&nbsp;&nbsp;
                            <Button type="button" variant="outlined" color="primary" className="no-shadow cancelBtn" onClick={() => navigate('/servicesearch/list')}>
                                Cancel
                            </Button>&nbsp;&nbsp;
                            <Button type="submit" variant="contained" color="primary" className="no-shadow addBtn">
                                Submit
                            </Button>
                        </Grid>
                    </form>
                )}
            </Formik>
        </Box>
    )
}
