import React, { useEffect, useState } from 'react';
import Moment from 'moment'
import config from '../config.json';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Card, CardContent, Button } from '@mui/material';
import Chart from 'react-apexcharts';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const DashboardWrapper = styled.div`
  .dashboardGradientCard {
    height: 110px;
  }

  .font07 {
    font-size: 0.7rem;
  }  

  .dashboardCardHeight {
    height: 100% !important;
  }
`;

const useStyles = makeStyles(() => ({
  customToastProgress: {
    backgroundColor: '#F59300',
  },
}))

export default function Dashboard() {
  const classes = useStyles();
  var [dashboardCount, setDashboardCount] = React.useState({
    serviceForm: 0,
    expenseForm: 0,
    rentCount: 0,
    totalCount: 0,
    serviceFormTotal: 0,
    expenseFormTotal: 0,
    rentCountTotal: 0
  });
  var [totalCount, setTotalCount] = React.useState(0);
  var [expenseSubmits, setExpenseSubmits] = useState([]);
  var [serviceSubmits, setServiceSubmits] = useState([]);
  const navigate = useNavigate();
  var [userEmail, setUserEmail] = React.useState("");
  var [userProfile, setUserProfile] = React.useState();
  const errorMessage = () => toast("An error occured, try again later", { progressClassName: classes.customToastProgress });

  const options = {
    chart: {
      type: 'pie',
    },
    labels: ['Service Form', 'Expense Form', 'Rent Count'],
    dataLabels: {
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex]
      }
    }
  };

  const ydtoptions = {
    chart: {
      type: 'pie',
    },
    labels: ['Service Form Total', 'Expense Form Total', 'Rent Count Total'],
    dataLabels: {
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex]
      }
    }
  };
  const series = [dashboardCount.serviceForm, dashboardCount.expenseForm, dashboardCount.rentCount]
  const ydtseries = [dashboardCount.serviceFormTotal, dashboardCount.expenseFormTotal, dashboardCount.rentCountTotal]


  useEffect(() => {
    userProfile = localStorage.getItem("userProfile");
    userProfile = JSON.parse(userProfile);
    setUserProfile(userProfile);
    userEmail = userProfile.userEmail;
    setUserEmail(userEmail)
    const MOBILESURETY_URL = config.API_URL + "/api/dashboard/count/" + userEmail
    const token = config.TOKEN
    axios.get(MOBILESURETY_URL, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          dashboardCount = res.data.data
          setDashboardCount(dashboardCount);
          if (dashboardCount !== undefined) {
            totalCount = (dashboardCount.pfCount + dashboardCount.uiCount + dashboardCount.exceptionCount);
            setTotalCount(totalCount)
          }
        } else {
          errorMessage()
        }
      })

    const MOBILESURETY_Submit_URL = config.API_URL + "/api/expense/search";

    axios.post(MOBILESURETY_Submit_URL, {
      portalPage: "DASHBOARD",
      "status": [],
      "qbClass": [],
      "userEmail": userEmail
    }, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          expenseSubmits = res.data.data
          setExpenseSubmits(expenseSubmits)
        } else {
          errorMessage()
        }
      })

    const MOBILESURIETY_serviceSubmit_URL = config.API_URL + "/api/service/search";
    axios.post(MOBILESURIETY_serviceSubmit_URL, {
      portalPage: "DASHBOARD", "status": [],
      "qbClass": [],
      "userEmail": userEmail
    }, {
      headers: {
        'Authorization': `Basic ${token}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 0) {
          serviceSubmits = res.data.data
          setServiceSubmits(serviceSubmits)
        } else {
          errorMessage()
        }
      })
  }, []);

  const ExpenseSubmitData = {
    header: ['Data Recorded Id', 'Date of Receipt', 'Employee Name', 'Expense Description', 'Expense Amount']
  }

  const ServiceSubmitData = {
    header: ['Data Recorded Id', 'Date', 'Customer', 'Item', 'Quantity']
  }

  return (
    <DashboardWrapper>
      <ToastContainer theme='dark' />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3} md={3}>
          <Card className="card-box bg-premium-dark dashboardGradientCard border-0 text-light mb-4">
            <CardContent className="p-3">
              <div className="d-flex align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-50 d-block mb-1 text-uppercase">
                    Service Form
                  </small>
                  <span className="font-size-xxl mt-1">{dashboardCount.serviceForm}</span>
                </div>
                <div className="ml-auto">
                  <div className="bg-white text-center text-success d-50 rounded-circle d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faChartBar} />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Card className="card-box noShadow bg-asteroid dashboardGradientCard border-0 text-light mb-4">
            <CardContent className="p-3">
              <div className="d-flex align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-50 d-block mb-1 text-uppercase">
                    Expense Form
                  </small>
                  <span className="font-size-xxl mt-1">{dashboardCount.expenseForm}</span>
                </div>
                <div className="ml-auto">
                  <div className="bg-white text-center text-success d-50 rounded-circle d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faChartBar} />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Card className="card-box noShadow bg-midnight-bloom dashboardGradientCard text-light mb-4">
            <CardContent className="p-3">
              <div className="d-flex align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-50 d-block mb-1 text-uppercase">
                    Rent Count
                  </small>
                  <span className="font-size-xxl mt-1">{dashboardCount.rentCount}</span>
                </div>
                <div className="ml-auto">
                  <div className="bg-white text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faChartBar} />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={3} md={3}>
          <Card className="card-box noShadow bg-night-sky dashboardGradientCard text-light mb-4">
            <CardContent className="p-3">
              <div className="d-flex align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-50 d-block mb-1 text-uppercase">
                    Total
                  </small>
                  <span className="font-size-xxl mt-1">{dashboardCount.totalCount}</span>
                </div>
                <div className="ml-auto">
                  <div className="bg-white text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faChartBar} />
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={6}>
          <Card className="card-box noShadow dashboardCardHeight mb-4">
            <div className="card-header pr-2">
              <div className="card-header--title">Pay Period Details</div>
            </div>
            <CardContent className="p-3">
              <Chart options={options} type="pie" series={series} width="380" />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Card className="card-box noShadow dashboardCardHeight mb-4">
            <div className="card-header pr-2">
              <div className="card-header--title">YTD Details</div>
            </div>
            <CardContent className="p-3">
              <Chart options={ydtoptions} type="pie" series={ydtseries} width="420" />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12}>
          <Card className="card-box noShadow mb-4">
            <div className="card-header pr-2">
              <div className="card-header--title">Expense Form Submission Details</div>
            </div>
            <CardContent className="p-3">
              <div className="table-responsive">
                <table className="table table-borderless table-hover text-nowrap mb-0">
                  <thead>
                    <tr>
                      {ExpenseSubmitData.header.map((headerItem, index) => (
                        <th className="text-left" key={index}>{headerItem}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {expenseSubmits.slice(0, 5).map((expenseSubmits, index) =>

                    (
                      <tr key={expenseSubmits.id}>
                        <td className="text-left">
                          <div className="text-black font07">{expenseSubmits.formDataId}</div>
                        </td>
                        <td className="text-left">
                          <div className="text-black font07">{expenseSubmits.createdDate = Moment(Date.parse(expenseSubmits.createdDate)).format('MM/DD/YYYY')}</div>
                        </td>
                        <td className="text-left">
                          <div className="text-black font07">{expenseSubmits.employeeName}</div>
                        </td>
                        <td className="text-left">
                          <div className="text-black font07">{expenseSubmits.expenseDesc}</div>
                        </td>
                        <td className="text-left">
                          <div className="text-black font07">{expenseSubmits.expenseAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardContent>
            <div className="card-footer d-flex justify-content-between text-right">
              <div className="w-100">
                <Button
                  size="small"
                  variant="outlined"
                  className="mr-3 cancelBtn"
                  color="primary"
                  onClick={() => navigate('/expensesearch/list')}>
                  View all
                </Button>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={12} sm={12}>
          <Card className="card-box noShadow mb-4">
            <div className="card-header pr-2">
              <div className="card-header--title">Service Form Submission Details</div>
            </div>
            <CardContent className="p-3">
              <div className="table-responsive">
                <table className="table table-borderless table-hover text-nowrap mb-0">
                  <thead>
                    <tr>{ServiceSubmitData.header.map((headerItem, index) => (
                      <th className="text-left" key={index}>{headerItem}</th>
                    ))}</tr>
                  </thead>
                  <tbody>
                    {serviceSubmits.slice(0, 5).map((serviceSubmits, index) => (
                      <tr key={serviceSubmits.id}>
                        <td className="text-left">
                          <div className="text-black font07">{serviceSubmits.formDataId}</div>
                        </td>
                        <td className="text-left">
                          <div className="text-black font07">{serviceSubmits.createdDate = Moment(Date.parse(serviceSubmits.createdDate)).format('MM/DD/YYYY')}</div>
                        </td>
                        <td className="text-left">
                          <div className="text-black font07">{serviceSubmits.customerRig}</div>
                        </td>
                        <td className="text-left">
                          <div className="text-black font07">{serviceSubmits.item}</div>
                        </td>
                        <td className="text-left">
                          <div className="text-black font07">{serviceSubmits.qty}</div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CardContent>
            <div className="card-footer d-flex justify-content-between text-right">
              <div className="w-100">
                <Button
                  size="small"
                  variant="outlined"
                  className="mr-3 cancelBtn"
                  color="primary"
                  onClick={() => navigate('/servicesearch/list')}>
                  View all
                </Button>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>

    </DashboardWrapper>
  );
}


