import React, { useState, useEffect } from "react";
import { Formik, Form } from 'formik';
import { Button, MenuItem, TextField, Card, CardContent, FormControl, FormControlLabel, Grid, Input, InputLabel } from '@mui/material';
import PageTitle from '../../../shared/components/PageTitle';
import axios from 'axios';
import clsx from 'clsx';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import config from '../../config.json';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(0),
  },
  withoutLabel: {
    marginTop: theme.spacing(0),
  },
  actionBtn: {
    padding: '8px',
    backgroundColor: '#f0f0f0',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: 'rgba(245,147,0,0.1)',
      color: '#F59300',
    },
    '&.active': {
      backgroundColor: '#F59305',
      color: 'white !important',
    },
  },
  customToastProgress: {
    backgroundColor: '#F59300',
  },
}));


const UserWrapper = styled.div`
    position: relative;
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        border : 1px solid #F59300 !important;
        color : #F59300 !important;
    }

    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }
    .customWidth : {
        width : 100% !important
    }
`;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AddExpense() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const token = config.TOKEN;
  let tokens = localStorage.getItem("token");
  tokens = JSON.parse(tokens)
  var [userProfile, setUserProfile] = React.useState();
  var [userType, setUserType] = React.useState("");
  var [userEmail, setUserEmail] = React.useState("");
  const { id } = useParams();
  var [rows, setRows] = React.useState({});
  const [value, setValue] = React.useState(0);
  const errorMessage = () => toast("An error occured, try again later", { progressClassName: classes.customToastProgress });
  const expenseUpdateMessage = () => toast("Expense Updated Successfully!", { progressClassName: classes.customToastProgress })
  const statusOptions = [
    'New',
    'PreApproved',
    'Approved',
    'Rejected',
    'Proceed',
    'Failed'
  ]

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    userProfile = localStorage.getItem("userProfile");
    userProfile = JSON.parse(userProfile);
    setUserProfile(userProfile);
    userType = userProfile.userType;
    setUserType(userType);
    userEmail = userProfile.userEmail;
    setUserEmail(userEmail);

    if (id !== undefined) {
      const MOBILESURETY_URL = config.API_URL + "/api/expensesearch/edit/" + id;
      axios.get(MOBILESURETY_URL, {
        headers: {
          'Authorization': `Basic ${token}`
        }
      })
        .then((res) => {
          if (res.data.successCode === 0) {
            rows = res.data.data;
            const status = res.data.data.status;
            rows.expHeader.dateOfReceipt = new Date(rows.expHeader.dateOfReceipt).toISOString().split('T')[0];
            setRows(rows);

          } else {
            errorMessage()
          }
        })
        .catch(error => {
          console.error("API Error:", error);
        });
    }
  }, [id]);


  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label='HEADER' {...a11yProps(0)} style={{ color: '#F59300' }} />
          <Tab label='EXPENSE DETAIL' {...a11yProps(1)} style={{ color: '#F59300' }} />
          <Tab label='ADDITIONAL NOTICE PAGE' {...a11yProps(2)} style={{ color: '#F59300' }} />
          <Tab label='SUBMIT' {...a11yProps(3)} style={{ color: '#F59300' }} />
        </Tabs>
      </Box>
      <Formik enableReinitialize={true}
        initialValues={{
          pfDataId: id,
          status: rows?.status,
          dateOfReceipt: rows?.expHeader?.dateOfReceipt || new Date().toISOString().split('T')[0],
          TransactionType: rows?.expHeader?.transactionType,
          CreditCard: rows?.expHeader?.creditCard,
          GlAccount: rows?.expHeader?.glAccount,
          EmployeeName: rows?.expHeader?.employeeName,
          ChargeToArea: rows?.expHeader?.chargeToArea,
          ChargeTo: rows?.expHeader?.chargeTo,
          VendorName: rows?.expHeader?.vendorName,
          Moa: rows?.expHeader?.moa,
          Category: rows?.expDetail?.category,
          Description: rows?.expDetail?.description,
          AccountNo: rows?.expDetail?.accountNo,
          Brief: rows?.expDetail?.brief,
          Amount: rows?.expDetail?.amount,
          ManagerEmail: rows?.expAdditionalNotice?.managerEmail,
          ClerkEmail: rows?.expAdditionalNotice?.clerkEmail,
          VehicleNo: rows?.expSubmit?.vehicleNo,
          Odometer: rows?.expSubmit?.odometer,
          NotificationEmailAddress: rows?.expSubmit?.notificationEmailAddress,
          qbError: rows?.qbError
        }}
        onSubmit={(values, { setSubmitting }) => {
          const postData = {
            pfDataId: id ? id : 0,
            status: values.status,
            userName: userEmail,
            qbError: values.qbError,
            expHeader: {
              dateOfReceipt: values.dateOfReceipt,
              transactionType: values.TransactionType,
              creditCard: values.CreditCard,
              glAccount: values.GlAccount,
              employeeName: values.EmployeeName,
              chargeToArea: values.ChargeToArea,
              chargeTo: values.ChargeTo,
              vendorName: values.VendorName,
              moa: values.Moa,
              stateInsp: "",

            },
            expDetail: {
              category: values.Category,
              description: values.Description,
              accountNo: values.AccountNo,
              brief: values.Brief,
              amount: values.Amount
            },
            expBenefit: {
              company: "",
              customer: "",
              customerAccount: "",
              otherNote: ""
            },
            expAdditionalNotice: {
              managerEmail: values.ManagerEmail,
              clerkEmail: values.ClerkEmail
            },
            expSubmit: {
              vehicleNo: values.VehicleNo,
              odometer: values.Odometer,
              notificationEmailAddress: values.NotificationEmailAddress
            }
          }
          const MOBILESURETY_URL = config.API_URL + "/api/expense/save";
          axios.post(MOBILESURETY_URL, postData, {
            headers: {
              'Authorization': `Basic ${token}`
            }
          })
            .then((res) => {
              if (res.data.successCode === 0) {
                expenseUpdateMessage()
              } else {
                errorMessage()
              }
              setTimeout(() => { navigate("/expensesearch/list") }, 3000)
            })
            .catch((error) => {
              errorMessage()
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ handleChange, handleBlur, values, setValues, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <CustomTabPanel value={value} index={0}>
              <div>
                <UserWrapper>
                  <ToastContainer theme="dark" />
                  <div></div>
                  <div></div>
                  <Grid container spacing={4} className="d-flex justify-content-center">
                    <Grid container spacing={2} style={{ margin: '20px' }}>
                      <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                        <TextField style={{ width: "100%" }}
                          className="filterDropdown"
                          id="status"
                          select
                          label="Select Class"
                          helperText=""
                          variant="standard"
                          name="status"
                          value={values.status || ""}
                          onChange={(e) => setValues({ ...values, status: e.target.value })}
                          onBlur={handleBlur}>
                          {statusOptions.map((status, index) => (
                            <MenuItem key={index} value={status}>
                              {status}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                        <TextField label='Date of receipt' variant="standard" type="Date" onChange={(e) => {
                          handleChange(e)
                          setValues({ ...values, dateOfReceipt: e.target.value })
                        }} onBlur={handleBlur} value={values.dateOfReceipt} />

                      </Grid>
                      <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                        <TextField label="Type of Transaction" variant="standard" name="TransactionType"
                          onChange={handleChange}
                          onBlur={handleBlur} value={values.TransactionType || ""} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                        <TextField label="Credit Card" variant="standard" name="CreditCard" onChange={handleChange} onBlur={handleBlur} value={values.CreditCard || ""} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                        <TextField label="GLAcct" variant="standard" name="GlAccount" onChange={handleChange} onBlur={handleBlur} value={values.GlAccount || ""} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                        <TextField label="Employee Name" variant="standard" name="EmployeeName" onChange={handleChange} onBlur={handleBlur} value={values.EmployeeName || ""} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                        <TextField label="Is this expense charged to your assigned area?" variant="standard" name="ChargeToArea" onChange={handleChange} onBlur={handleBlur} value={values.ChargeToArea || ""} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                        <TextField label="Charge to: Field Office/Class" variant="standard" name="ChargeTo" onChange={handleChange} onBlur={handleBlur} value={values.ChargeTo || ""} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                        <TextField label="Vendor-Choose Vendor Name" variant="standard" name="VendorName" onChange={handleChange} onBlur={handleBlur} value={values.VendorName || ""} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                        <TextField label="Vendor-Enter Name if New Vendor" variant="standard" name="VendorName" onChange={handleChange} onBlur={handleBlur} value={values.VendorName || ""} />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                        <TextField label="Necessary MOA" variant="standard" name="Moa"
                          onChange={handleChange} onBlur={handleBlur} value={values.Moa || ""} />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} className="pt-3">
                        <TextField label="Qb Status" variant="standard" name="QbStatus" value={values.qbError || ""} disabled />
                      </Grid>
                    </Grid>
                  </Grid>
                </UserWrapper>
              </div>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <UserWrapper>
                <Grid container spacing={4} className="d-flex justify-content-center">
                  <Grid container spacing={2} style={{ margin: '20px' }}>
                    <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                      <TextField label='Expense Category' variant="standard" name="Category" onChange={handleChange} onBlur={handleBlur} value={values.Category || ""} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                      <TextField label='Expense Description' variant="standard" name="Description" onChange={handleChange} onBlur={handleBlur} value={values.Description || ""} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                      <TextField label="Expense Acc#" variant="standard" name="AccountNo" onChange={handleChange} onBlur={handleBlur} value={values.AccountNo || ""} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                      <TextField label="Expense Explanation (Brief)" variant="standard" name="Brief" onChange={handleChange} onBlur={handleBlur} value={values.Brief || ""} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                      <TextField label="Expense Amount" variant="standard" name="Amount" onChange={handleChange} onBlur={handleBlur} value={values.Amount || ""} />
                    </Grid>
                  </Grid>
                </Grid>
              </UserWrapper>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
              <UserWrapper>
                <Grid container spacing={4} className="d-flex justify-content-center">
                  <Grid item xs={12} sm={8} md={8} className="d-flex justify-content-center">
                    <TextField label="Manager Email for Charged Area" variant="standard" name="ManagerEmail" onChange={handleChange} onBlur={handleBlur} value={values.ManagerEmail || ""} />
                  </Grid>
                  <Grid item xs={12} sm={8} md={8} className="d-flex justify-content-center">
                    <TextField label="Clerk Email for Charged Area" variant="standard" name="ClerkEmail" onChange={handleChange} onBlur={handleBlur} value={values.ClerkEmail || ""} />
                  </Grid>
                </Grid>
              </UserWrapper>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={3}>
              <UserWrapper>
                <Grid container spacing={4} className="d- flex justify-content-center">
                  <Grid container spacing={2} style={{ margin: '20px' }}>
                    <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                      <TextField label='Vehicle#' variant="standard" name="VehicleNo" onChange={handleChange} onBlur={handleBlur} value={values.VehicleNo || ""} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                      <TextField label="Odometer" variant="standard" name="Odometer" onChange={handleChange} onBlur={handleBlur} value={values.Odometer || ""} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className="pt-3 d-flex justify-content-center">
                      <TextField label="Geo Location" variant="standard" name="NotificationEmailAddress" onChange={handleChange} onBlur={handleBlur} value={values.NotificationEmailAddress || ""} />
                    </Grid>
                  </Grid>
                </Grid>
              </UserWrapper>
            </CustomTabPanel>
            <Grid xs={12} sm={10} md={10} className="text-right mt-4">
              <Button type="button" variant="outlined" color="primary" className="no-shadow cancelBtn" onClick={() => navigate('/expensesearch/list')}>
                Cancel
              </Button>&nbsp;&nbsp;
              <Button type="submit" variant="contained" color="primary"
                className="no-shadow addBtn">
                Submit
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  )
}
