import React, { useEffect } from 'react'
import styled from 'styled-components';
import { Button, TextField, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(0),
  },
  withoutLabel: {
    marginTop: theme.spacing(0),
  },
  customToastProgress: {
    backgroundColor: '#F59300',
  },
}));


const UserWrapper = styled.div`
    position: relative;
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        border : 1px solid #F59300 !important;
        color : #F59300 !important;
    }

    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }
    .customWidth : {
        width : 100% !important
    }
`;


export default function AddRental() {
  const classes = useStyles();
  const { id } = useParams();
  const token = config.TOKEN;
  const navigate = useNavigate();
  var [rows, setRows] = React.useState("");
  var [userType, setUserType] = React.useState("");
  var [userProfile, setUserProfile] = React.useState();
  var [userEmail, setUserEmail] = React.useState("");
  const errorMessage = () => toast("An error occured, try again later", { progressClassName: classes.customToastProgress });
  const addRentalMessage = () => toast("Rental Added successfully!", { progressClassName: classes.customToastProgress });
  const updateRentalMessage = () => toast("Rental Updated Successfully!", { progressClassName: classes.customToastProgress });

  useEffect(() => {
    userProfile = localStorage.getItem("userProfile");
    userProfile = JSON.parse(userProfile);
    setUserProfile(userProfile);
    userType = userProfile.userType;
    setUserType(userType);
    userEmail = userProfile.userEmail;
    setUserEmail(userEmail);

    if (id) {
      const MOBILESURETY_URL = config.API_URL + "/api/rental/" + id;
      axios.get(MOBILESURETY_URL, {
        headers: {
          'Authorization': `Basic ${token}`
        }
      })
        .then((res) => {
          if (res.data.successCode === 0) {
            const rows = res.data.data;
            rows.entryDate = new Date(rows.entryDate).toISOString().split('T')[0];
            rows.startDate = new Date(rows.startDate).toISOString().split('T')[0];
            rows.endDate = new Date(rows.endDate).toISOString().split('T')[0];
            setRows(rows);
          } else {
            errorMessage()
          }
        })
    }
  }, [id])

  return (
    <div>
      <ToastContainer theme="dark" />
      <Formik enableReinitialize={true}
        initialValues={{
          RentType: rows?.rentType,
          entryDate: rows?.entryDate || new Date().toISOString().split('T')[0],
          RefNumber: rows?.refNumber,
          CustomerId: rows?.customerId,
          CustomerRigName: rows?.customerRigName,
          AfeNumber: rows?.afeNumber,
          LeaseName: rows?.leaseName,
          Terms: rows?.terms,
          startDate: rows?.startDate || new Date().toISOString().split('T')[0],
          endDate: rows?.endDate || new Date().toISOString().split('T')[0],
          ItemNo: rows?.itemNo,
          Item: rows?.item,
          Memo: rows?.memo,
          AssetNumber: rows?.assetNumber,
          Qty: rows?.qty,
          SalesPrice: rows?.salesPrice !== undefined ? String(rows.salesPrice) : "",
          QbClass: rows?.qbClass,
          qbError: rows?.qbError
        }}
        validate={(values) => {
          const errors = {};
          if (!values.RentType) {
            errors.RentType = " Required!";
          }
          if (!values.entryDate) {
            errors.entryDate = "Required!";
          }
          if (!values.RefNumber) {
            errors.RefNumber = "Required!";
          }
          if (!values.CustomerId) {
            errors.CustomerId = "Required!";
          }
          if (!values.CustomerRigName) {
            errors.CustomerRigName = "Required!";
          }
          if (!values.AfeNumber) {
            errors.AfeNumber = "Required!";
          }
          if (!values.LeaseName) {
            errors.LeaseName = "Required!";
          }
          if (!values.Terms) {
            errors.Terms = "Required!";
          }
          if (!values.startDate) {
            errors.startDate = "Required!";
          }
          if (!values.endDate) {
            errors.endDate = "Required!";
          }
          if (!values.ItemNo) {
            errors.ItemNo = "Required!";
          }
          if (!values.Item) {
            errors.Item = "Required!";
          }
          if (!values.Memo) {
            errors.Memo = "Required!";
          }
          if (!values.AssetNumber) {
            errors.AssetNumber = "Required!";
          }
          if (!values.Qty) {
            errors.Qty = "Required!";
          }
          if (!values.SalesPrice) {
            errors.SalesPrice = "Required!";
          }
          if (!values.QbClass) {
            errors.QbClass = "Required!";
          }
          return errors;
        }}

        const handleMouseDownPwd={(event) => {
          event.preventDefault();
        }}

        onSubmit={(values, { setSubmitting }) => {
          const postData = {
            id: id ? id : 0,
            userName: userEmail,
            rentType: values.RentType,
            entryDate: values.entryDate,
            refNumber: values.RefNumber,
            customerId: values.CustomerId,
            customerRigName: values.CustomerRigName,
            afeNumber: values.AfeNumber,
            leaseName: values.LeaseName,
            terms: values.Terms,
            startDate: values.startDate,
            endDate: values.endDate,
            itemNo: values.ItemNo,
            item: values.Item,
            memo: values.Memo,
            assetNumber: values.AssetNumber,
            qty: values.Qty,
            salesPriceString: String(values.SalesPrice),
            salesPrice: values.SalesPrice,
            qbClass: values.QbClass,
            CreatedBy: "",
            UpdatedBy: "",
            CurrentStatus: ""
          };

          // same api for add & edit
          const MOBILESURETY_URL = config.API_URL + "/api/rent/add";
          axios.post(MOBILESURETY_URL, postData, {
            headers: {
              'Authorization': `Basic ${token}`
            }
          })
            .then((res) => {
              if (res.data.successCode === 0) {
                { id ? updateRentalMessage() : addRentalMessage() }
              } else {
                errorMessage()
              }
              setTimeout(() => { navigate("/rentalsearch/list") }, 3000)
            })
            .catch((error) => {
              errorMessage()
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setValues,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <UserWrapper>
              <ToastContainer theme='dark' />
              <Grid container spacing={4} className='d-flex justify-content-center'>
                <Grid item xs={12} sm={12} md={12} className="pt-5 d-flex justify-content-left" style={{ paddingLeft: "35px" }}>
                  <PageTitle>Rental Form</PageTitle>
                </Grid>
                <Grid container spacing={2} style={{ margin: '20px', marginTop: "0px" }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField label="Rental Type" variant="standard" name="RentType" onChange={handleChange} onBlur={handleBlur}
                      value={values.RentType || ""} />
                    <span className="errMsg">
                      {errors.RentType && touched.RentType && errors.RentType}
                    </span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="Entry Date" variant="standard" type="Date" onChange={(e) => { handleChange(e); setValues({ ...values, entryDate: e.target.value }) }} onBlur={handleBlur} value={values.entryDate} />
                    <span className="errMsg">{errors.entryDate && touched.entryDate && errors.entryDate}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="Ref Number" variant="standard" name="RefNumber" onChange={handleChange} onBlur={handleBlur} value={values.RefNumber || ""} />
                    <span className="errMsg">{errors.RefNumber && touched.RefNumber && errors.RefNumber}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="Customer ID" variant="standard" name="CustomerId" onChange={handleChange} onBlur={handleBlur} value={values.CustomerId || ""} />
                    <span className="errMsg">{errors.CustomerId && touched.CustomerId && errors.CustomerId}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="Customer Rig Name" variant="standard" name="CustomerRigName" onChange={handleChange} onBlur={handleBlur} value={values.CustomerRigName || ""} />
                    <span className="errMsg">{errors.CustomerRigName && touched.CustomerRigName && errors.CustomerRigName}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="AFE Number" variant="standard" name="AfeNumber" onChange={handleChange} onBlur={handleBlur} value={values.AfeNumber || ""} />
                    <span className="errMsg">{errors.AfeNumber && touched.AfeNumber && errors.AfeNumber}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="Lease Name" variant="standard" name="LeaseName" onChange={handleChange} onBlur={handleBlur} value={values.LeaseName || ""} />
                    <span className="errMsg">{errors.LeaseName && errors.LeaseName && errors.LeaseName}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="Terms" variant="standard" name="Terms" onChange={handleChange} onBlur={handleBlur} value={values.Terms || ""} />
                    <span className="errMsg">{errors.Terms && errors.Terms && errors.Terms}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="Start Date" variant="standard" type="Date" onChange={(e) => { handleChange(e); setValues({ ...values, startDate: e.target.value }) }} onBlur={handleBlur} value={values.startDate} />
                    <span className="errMsg">{errors.startDate && touched.startDate && errors.startDate}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="End Date" variant="standard" type="Date" onChange={(e) => { handleChange(e); setValues({ ...values, endDate: e.target.value }) }} onBlur={handleBlur} value={values.endDate} />
                    <span className="errMsg">{errors.endDate && touched.endDate && errors.endDate}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="Item No" variant="standard" name="ItemNo" onChange={handleChange} onBlur={handleBlur} value={values.ItemNo || ""} />
                    <span className="errMsg">{errors.ItemNo && errors.ItemNo && errors.ItemNo}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="Item" variant="standard" name="Item" onChange={handleChange} onBlur={handleBlur} value={values.Item || ""} />
                    <span className="errMsg">{errors.Item && errors.Item && errors.Item}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="Memo" variant="standard" name="Memo" onChange={handleChange} onBlur={handleBlur} value={values.Memo || ""} />
                    <span className="errMsg">{errors.Memo && errors.Memo && errors.Memo}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="Asset Number" variant="standard" name="AssetNumber" onChange={handleChange} onBlur={handleBlur} value={values.AssetNumber || ""} />
                    <span className="errMsg">{errors.AssetNumber && errors.AssetNumber && errors.AssetNumber}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="Qty" variant="standard" name="Qty" onChange={handleChange} onBlur={handleBlur} value={values.Qty || ""} />
                    <span className="errMsg">{errors.Qty && touched.Qty && errors.Qty}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="Sale Price" variant="standard" name="SalesPrice" onChange={handleChange} onBlur={handleBlur} value={values.SalesPrice || ""} />
                    <span className="errMsg">{errors.SalesPrice && touched.SalesPrice && errors.SalesPrice}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="TC Class" variant="standard" name="QbClass" onChange={handleChange} onBlur={handleBlur} value={values.QbClass || ""} />
                    <span className="errMsg">{errors.QbClass && touched.QbClass && errors.QbClass}</span>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} className="pt-3">
                    <TextField label="TC Status" variant="standard" name="QbStatus" value={values.qbError || ""} disabled />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={10} md={10} className="text-right mt-4">
                  <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/rentalsearch/list")}>
                    Cancel
                  </Button>&nbsp;&nbsp;
                  <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </UserWrapper>
          </form>
        )}
      </Formik>
    </div>
  )
}
