import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Button, MenuItem, TextField, Card, CardContent, FormControl, Autocomplete, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import clsx from 'clsx';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { useParams } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    },
    customToastProgress: {
        backgroundColor: '#F59300',
    },
}));

const UserWrapper = styled.div`
    position: relative;
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        border : 1px solid #F59300 !important;
        color : #F59300 !important;
    }
    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }

    .cardStyle {
        backgroundColor : blue !important;
    }
`;

export default function AddUser() {
    const classes = useStyles();
    let tokens = localStorage.getItem("token");
    tokens = JSON.parse(tokens)
    const token = config.TOKEN;
    const { id } = useParams();
    const navigate = useNavigate();
    var [qbClassList, setQbClassList] = React.useState([]);
    const [selectedClasses, setSelectedClasses] = useState([]);
    var [rows, setRows] = React.useState([
        {
            "name": "",
            "qbName": "",
            "email": " ",
            "isActive": false,
            "customerId": null,
            "regionId": null,
            "classnameId": null,
            "qbClass": [
                {
                    "id": 5
                },
                {
                    "id": 6
                },
                {
                    "id": 7
                }
            ]
        }
    ]);
    var [userType, setUserType] = React.useState("");
    var [userEmail, setUserEmail] = React.useState("");
    var [userProfile, setUserProfile] = React.useState();
    var [disableEmail, setDisableEmail] = React.useState(false);
    const [classname, setClassname] = useState('');
    const [classId, setClassId] = useState(0);
    const [classnameError, setClassnameError] = useState("");
    var [classnameArray, setClassnameArray] = useState([]);
    var [showClassname, setShowClassname] = useState();
    const deleteClassnameMessage = () => toast("Can't delete the Region, because sites are associated with it!", { progressClassName: classes.customToastProgress });
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" style={{ color: '#F59300' }} />;
    const checkedIcon = <CheckBoxIcon fontSize="small" style={{ color: '#F59300' }} />;
    const addUserMessage = () => toast("User Added Successfully!", { progressClassName: classes.customToastProgress });
    const updateUserMessage = () => toast("User Updated Successfully!", { progressClassName: classes.customToastProgress });
    const errorMessage = () => toast("An error occured, try again later", { progressClassName: classes.customToastProgress });

    useEffect(() => {
        userProfile = localStorage.getItem("userProfile");
        userProfile = JSON.parse(userProfile);
        setUserProfile(userProfile);
        userType = userProfile.userType;
        setUserType(userType)
        userEmail = userProfile.userEmail
        setUserEmail(userEmail)

        if (id) {
            disableEmail = true
            setDisableEmail(disableEmail)
            const MOBILESURETY_USER_URL = config.API_URL + "/api/user/" + id
            axios.get(MOBILESURETY_USER_URL, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 0) {
                        rows = res.data.data
                        if (rows?.qbClass) {
                            setSelectedClasses(rows.qbClass.map((qbClass) => ({ id: qbClass.id, className: qbClass.className })));
                        }
                        setRows(rows);
                    } else {
                        errorMessage()
                    }
                })
        }

        const MOBILESURETY_USER_URL = config.API_URL + "/api/qbclass/all/" + userEmail
        axios.get(MOBILESURETY_USER_URL, {
            headers: {
                'Authorization': `Basic ${token}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 0) {
                    let employees = res.data.data
                    qbClassList = employees.filter(e => e.isActive == true).map(e => ({ "className": e.className, "id": e.id }))
                    setQbClassList(qbClassList)
                } else {
                    errorMessage()
                }
            })

    }, [id])

    const deleteClassname = (i, e) => {
        if (e.siteCount > 0) {
            deleteClassnameMessage()
        } else {
            setClassnameArray((classnameArray) => {
                return classnameArray.filter((arrElem, index) => {
                    return index !== i;
                });
            });
        }
    }

    const UserTypeList = ["Admin", "Manager", "User"]

    return (
        <div>
            <ToastContainer theme="dark" />
            <Formik
                enableReinitialize={true}
                initialValues={{
                    Name: rows?.fullName, Email: rows?.userEmail, IsActive: rows?.isActive,
                    UserType: rows?.userType ? rows?.userType : null,
                    Password: (rows?.userEmail ? "     " : ""),
                    QbClass: selectedClasses

                }}

                validate={values => {
                    const errors = {};
                    if (!values.Name) {
                        errors.Name = 'Required!';
                    }

                    if (!values.Email) {
                        errors.Email = 'Required!';
                    }

                    if (!values.Password) {
                        errors.Password = 'Required!';
                    }

                    return errors;
                }}

                const handleMouseDownPwd={(event) => {
                    event.preventDefault();
                }}
                onSubmit={(values, { setSubmitting }) => {

                    const postData = {
                        id: id ? id : 0,
                        userName: values.Email,
                        fullName: values.Name,
                        userEmail: values.Email,
                        isActive: values.IsActive,
                        userType: values.UserType,
                        pwd: values.Password,
                        pfId: "0",
                        userKey: "userkey",
                        token: "token",
                        qbClass: values.QbClass
                    }
                    // same api for add & edit
                    const MOBILESURETY_URL = config.API_URL + "/api/user/add"
                    axios.post(MOBILESURETY_URL, postData, {
                        headers: {
                            'Authorization': `Basic ${token}`
                        }
                    })
                        .then((res) => {

                            if (res.data.successCode === 0) {
                                { id ? updateUserMessage() : addUserMessage() }
                            } else {
                                errorMessage()
                            }
                            setTimeout(() => { navigate("/user/list") }, 3000)
                        })
                    setSubmitting(false)
                }}
            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue

                }) => (
                    <form onSubmit={handleSubmit}>
                        <UserWrapper>
                            <ToastContainer theme='dark' />
                            <Grid container spacing={4} className='d-flex justify-content-center'>
                                <Grid item xs={12} sm={8} md={8} className="pt-4">
                                    <PageTitle>Employee</PageTitle>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Name" variant="standard" value={values.Name || ''} onBlur={handleBlur} name="Name" onChange={handleChange} />
                                    <span className="errMsg">{errors.Name && touched.Name && errors.Name}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Email" variant="standard" value={values.Email || ''} onBlur={handleBlur} disabled={disableEmail} name="Email" onChange={handleChange} />
                                    <span className="errMsg">{errors.Email && touched.Email && errors.Email}</span>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <FormControl fullWidth className={clsx(classes.withoutLabel, classes.textField)}>
                                        <TextField
                                            style={{ width: "99%" }}
                                            className="filterDropdown"
                                            id="status"
                                            select
                                            label="User Type"
                                            helperText=""
                                            variant="standard"
                                            name="UserType"
                                            value={values.UserType || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}>
                                            {UserTypeList.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <TextField label="Password" type='password' variant="standard" value={values.Password || ''} onBlur={handleBlur} name="Password" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="pt-3">
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        className='fiterDropdown'
                                        options={qbClassList}
                                        getOptionLabel={(option) => option.className}
                                        name="QbClass"
                                        value={values.QbClass || ''}
                                        onChange={(event, value) => setFieldValue('QbClass', value)}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.className}
                                            </li>
                                        )}
                                        style={{ width: "100%", cursor: "pointer" }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select Classes" variant="standard" />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={8} md={8} className="text-right mt-4">
                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/user/list")}>
                                        Cancel
                                    </Button>&nbsp;&nbsp;
                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </UserWrapper>
                    </form>
                )}
            </Formik>
        </div>
    );
}
