import React, { lazy, Suspense, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from '@mui/styles';
import MuiTheme from './theme';
import { LeftSidebar, PresentationLayout } from './layout-blueprints';
import { Loader } from './shared/components/Loader';
import Dashboard from './pages/Dashboard';
import UserList from './pages/Users/Users/UserList';
import AddUser from './pages/Users/AddUser';
import ExpenseSearch from './pages/Expense/ExpenseSearch';
import AddExpenseSearch from './pages/Expense/AddExpenseSearch';
import ServiceSearch from './pages/Service/ServiceSearch/ServiceSearch';
import AddServiceSearch from './pages/Service/AddServiceSearch';
import ResetPassword from './pages/ForgotPassword/ResetPassword';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import RentalSearch from './pages/Rental/RentalSearch';
import AddRental from './pages/Rental/AddRental';
import RentalUpload from './pages/Rental/RentalUpload';


const Login = lazy(() => import('./pages/Login'));
const RoutesComponent = () => {

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense
          fallback={
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
              <div className="w-50 mx-auto">
                <Loader color="#ff9900" />
              </div>
            </div>
          }>
          <PresentationLayout>
            {/* login */}
          </PresentationLayout>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path="/resetpwd/:pwdkey" element={<ForgotPassword />}></Route>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route element={<LeftSidebar />}>
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route path="/user/list" element={<UserList />}></Route>
              <Route path="/user/add" element={<AddUser />}></Route>
              <Route path="/user/edit/:id" element={<AddUser />}></Route>
              <Route path="/user/reset-password" element={<ResetPassword />}></Route>
              <Route path='/expensesearch/list' element={<ExpenseSearch/>}></Route>
              <Route path='/expense/edit/:id' element={<AddExpenseSearch/>}></Route>
              <Route path='/servicesearch/list' element={<ServiceSearch/>}></Route>
              <Route path='/service/edit/:id' element={<AddServiceSearch/>}></Route>
              <Route path='/rentalsearch/list' element={<RentalSearch/>}></Route>
              <Route path='/rental/add' element={<AddRental/>}></Route>
              <Route path='/rental/edit/:id' element={<AddRental/>}></Route>
              <Route path='/rental/upload' element={<RentalUpload/>}></Route>
            </Route>
          </Routes>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default RoutesComponent;
